import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import debounce from 'lodash.debounce';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import required from '../../utilities/required';
import __ from '../../utilities/__';

const mapStateToProps = (state: RootState, props: { form: string }) => {
  const selector = formValueSelector(props.form);
  return {
    address: selector(state, 'address'),
  };
};

type Props = RouteProps<{ appSpace: string }> &
  ReduxConnectProps & { form: string } & ReturnType<typeof mapStateToProps>;

const AdminSportGroundForm: React.FC<Props> = ({
  form,
  params: { appSpace },
  dispatch,
  address,
}) => {
  const [addressCache, setAddressCache] = React.useState<{
    [key: string]: Array<{
      displayName: string;
      lat: string;
      lon: string;
      address: {
        country: string;
        road?: string;
        city_district?: string;
        county?: string;
        house_number?: string;
        postcode?: string;
      };
    }>;
  }>({});
  const [query, setQuery] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);

  const [Leaflet, setLeaflet] = React.useState<any>(null);

  React.useEffect(() => {
    if (window) {
      const component = require('react-leaflet');
      setLeaflet({
        Map: component.Map,
        Marker: component.Marker,
        TileLayer: component.TileLayer,
      });
    }
  }, []);

  const serializeQueryParams = (parameters: { [key: string]: any }) => {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  };
  const onChange = async (q: string) => {
    try {
      setIsFetching(true);
      const parameters = serializeQueryParams({
        format: 'json',
        addressdetails: 1,
        limit: 50,
        countrycodes: 'sk',
        q,
      });
      const res = await fetch(
        `https://nominatim.openstreetmap.org/search?${parameters}`,
      );
      const data = await res.json();

      setAddressCache((i) => {
        return {
          ...i,
          [q]: data
            .filter((item: any) => item.address.postcode)
            .map((item: any) => ({
              ...item,
              displayName: `${item.address.road || ''}${
                item.address.house_number ? ` ${item.address.house_number}` : ''
              }${item.address.postcode ? ` ${item.address.postcode}` : ''}${
                item.address.county ? ` ${item.address.county}` : ''
              }${
                item.address.city_district && !item.address.county
                  ? ` ${item.address.city_district}`
                  : ''
              }`,
            })),
        };
      });
    } catch (e: any) {
      alert(__('Adresu sa nepodarilo nájsť'));
    } finally {
      setIsFetching(false);
    }
  };

  const searchAddress = React.useCallback(debounce(onChange, 1000), []);

  return (
    <>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Vyhľadávanie')}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12}>
            <Field
              label={__('Adresa')}
              name="search"
              component={FormField}
              type="theselect"
              required
              loading={isFetching}
              options={
                addressCache[query]
                  ? addressCache[query]
                      .map((i) => i.displayName)
                      .filter((v, i, arr) => arr.indexOf(v) === i)
                      .map((i) => {
                        return {
                          label: i,
                          value: i,
                        };
                      })
                  : []
              }
              onChange={(e: any) => {
                if (e) {
                  const item = (addressCache[query] || []).find(
                    (i) => i.displayName === e.value,
                  );
                  if (item) {
                    dispatch(
                      change(form, 'address', {
                        country: item.address.country,
                        street:
                          item.address.road ||
                          item.address.city_district ||
                          item.address.county,
                        number: item.address.house_number || '',
                        city: item.address.city_district || item.address.county,
                        zip: item.address.postcode,
                        geo: {
                          lat: Number(item.lat),
                          lng: Number(item.lon),
                        },
                      }),
                    );
                  }
                }
                return e;
              }}
              filterOptions={(options: any[]) => options.filter((i) => i)}
              validate={[required]}
              onChangeInput={async (q: string) => {
                dispatch(change(form, 'address', {}));
                dispatch(change(form, 'search', null));
                if (!addressCache[q] && q.length >= 3) {
                  await searchAddress(q);
                }
                setQuery(q);
              }}
            />
          </Col>
        </Row>
      </Segment>
      {Object.keys(address || {}).length > 0 && (
        <>
          <Segment raised>
            <Row>
              <Col xs={12} s={8}>
                <Field
                  label={__('Ulica')}
                  name="address.street"
                  component={FormField}
                  required
                  validate={[required]}
                />
              </Col>
              <Col xs={12} s={4}>
                <Field
                  label={__('Číslo domu')}
                  name="address.number"
                  component={FormField}
                  required
                  validate={[required]}
                />
              </Col>
              <Col xs={12} s={4}>
                <Field
                  label={__('PSČ')}
                  name="address.zip"
                  component={FormField}
                  required
                  validate={[required]}
                />
              </Col>
              <Col xs={12} s={8}>
                <Field
                  label={__('Obec')}
                  name="address.city"
                  component={FormField}
                  required
                  validate={[required]}
                />
              </Col>
              <Col xs={12}>
                <Field
                  label={__('Štát')}
                  name="address.country"
                  component={FormField}
                  required
                  validate={[required]}
                />
              </Col>
            </Row>
          </Segment>
          <Segment raised>
            {!!Leaflet && (
              <Leaflet.Map
                style={{ height: rem(320) }}
                center={[address.geo.lat, address.geo.lng]}
                zoom={16}
              >
                <Leaflet.TileLayer
                  url={`https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  tileSize={512}
                  zoomOffset={-1}
                />
                <Leaflet.Marker
                  draggable
                  ondragend={(i: {
                    target: { _latlng: { lat: number; lng: number } };
                  }) => {
                    const { lat, lng } = i.target._latlng;
                    dispatch(change(form, 'address.geo', { lat, lng }));
                  }}
                  position={[address.geo.lat, address.geo.lng]}
                />
              </Leaflet.Map>
            )}
          </Segment>
        </>
      )}
    </>
  );
};

export default compose(
  reduxForm({ enableReinitialize: true }),
  withRouter,
  connect(mapStateToProps),
)(AdminSportGroundForm);
